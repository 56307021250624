/*
 * This function retrieves content from advocate studio.
 * It takes a Advocate Studio code, of the form [id]X[api_code],
 * And a key and returns either a stored or cached value.
 */

// localStorage key for AS code and context identifier
const keyForCode = (code, contextKey) => `as_${code}${contextKey ? ':' : ''}${contextKey || ''}`;

// Post request to AS
const post = (code, target) => {
  const targetString = target ? `&target=${target}` : '';
  return fetch('https://thl.advocate.studio/app/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: `r=api-${code}${targetString}`,
  })
    .then(response => response.json())
    .catch(() => '');
};

// Register requests in an object, to avoid unnecessary refetching.
let requestRegister = {};

// Cleared stored requests, used in tests.
export const clearRequestRegister = () => {
  requestRegister = {};
};

// If a request has been made, use the promise returned.
// Otherwise, post a new request.
// Note, this will prevent content refetching until page is reloaded.
const retrieveStoredOrPost = async (code, contextKey, target) => {
  const registerKey = keyForCode(code, contextKey);

  if (requestRegister[registerKey]) {
    return requestRegister[registerKey];
  }
  const postPromise = post(code, target);
  requestRegister[registerKey] = postPromise;
  return postPromise;
};

export default async (code, options = {}) => {
  const { contextKey, target, messagePart = 'phrase_main' } = options;
  let cachedContent;
  let expirationDate;

  try {
    const storedObject = JSON.parse(localStorage.getItem(keyForCode(code, contextKey)) || '{}');
    // eslint-disable-next-line prefer-destructuring
    cachedContent = storedObject.cachedContent;
    // eslint-disable-next-line prefer-destructuring
    expirationDate = storedObject.expirationDate;
    // eslint-disable-next-line no-empty
  } catch (_e) {}

  const isExpired = expirationDate && new Date(expirationDate).getTime() < new Date().getTime();

  let content;

  try {
    // Look for cached un-expired content
    if (!cachedContent || isExpired) {
      // If none, post to api (or retrieve ongoing post)
      const resObj = await retrieveStoredOrPost(code, contextKey, target);
      const {
        // phrase_main: phraseMain,
        cache = 0,
        // phrase_support: phraseSupport, // unused
        // targetEmailAddress, // unused
        // targetTwitterReply, // unused
      } = resObj;

      const expiry = new Date();
      expiry.setHours(expiry.getHours() + cache);

      localStorage.setItem(
        `as_${code}`,
        JSON.stringify({
          cachedContent: resObj,
          expirationDate: expiry,
        }),
      );

      content = resObj[messagePart];
    } else {
      content = cachedContent[messagePart];
    }
    // eslint-disable-next-line no-empty
  } catch (_e) {}

  return content;
};
