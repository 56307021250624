import React from 'react';
import getASContent from '+/utils/custom/getASContent';

export const AdvocateStudio = ({ code, contextKey, messagePart = 'phrase_main' }) => {
  const [content, setContent] = React.useState();

  React.useEffect(() => {
    const setFromAS = async() => {
      if (!content) {
        const relevantContent = await getASContent(code, { contextKey, messagePart });
        setContent(relevantContent);
      }
    };
    setFromAS();
  }, []);

  return content || null;
};
