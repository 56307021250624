// ====================================================================
// Theme Settings Assignments Custom (CSS)
// ====================================================================
//
// This file contains modifications of the assignments of the base palette to specific roles.
// Modifications are included here to avoid conflicts in git.
//
// --------------------------------------------------------------------
// This file should only contain SCSS VARIABLES to be used within
// theme styles and styled-components.
// --------------------------------------------------------------------

export default variables => variables;
