import { AdvocateStudio } from './custom/AdvocateStudio';
import React from 'react';

// eslint-disable-next-line no-unused-vars
export default (directiveNodeRef, _directiveOptions) => {
  if (directiveNodeRef.name === 'advstu') {
    let code;
    try {
      code = directiveNodeRef.node.children[0].value;
    } catch (e) {
      code = null;
    }
    if (!code) return null;
    const { contextKey, messagePart } = directiveNodeRef.node.attributes;
    return <AdvocateStudio code={code} contextKey={contextKey} messagePart={messagePart} />;
  }
  return null;
};
