// ====================================================================
// Theme Settings (CSS)
// ====================================================================

// --------------------------------------------------------------------
// This file should only contain SCSS VARIABLES to be used within
// theme styles and styled-components.
// --------------------------------------------------------------------

export default variables => variables;
