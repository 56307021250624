/**
 * Exports custom properties for appConfig object.
 * @module appConfigCustom
 */

module.exports = {
  integrations: ['everyaction'],
  defaultLogo: '/mended-heart-icon.svg', // logo used in several places
  googleClientId: '247324730601-qe6apf5vuurhprv0981cec7052lks1al.apps.googleusercontent.com',
  googleTagManagerId: 'GTM-PQRDH97',
  defaultEmailNotificationsSetting: 'daily',
  dailyActionEmailSendHour: 16, // 10 EST
  donateUrl: 'http://thehumaneleague.org/donate?sourceid=1040977', // Url to direct users to make donations
  disableWebsite: false,
  disabledWebsiteMessage: 'This site is down for maintanence.',
  enableCustomContentParsers: true,
  emailSender: {
    address: 'actions@thehumaneleague.org',
    name: 'Dani Fahs',
    genericName: 'Action App',
  },
  sendWelcomeEmails: false,
  signInMethods: ['google', 'password'],
  userGroups: ['user', 'employee', 'public', 'admin'],
  defaultUserGroup: 'user',
  feedbackFormUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSeQoZNJUonInCdn4gCX3XWgIHNDV_NJCXmWQwtydby1mGDuEg/viewform?usp=sf_link',
  publicVapid: 'BDWfZdSJRFFFEOY2B3G0UY8mP6t4fkEAFkix8Mxp_5hX8v1ZOySBxiq3knSqYbvuKiIy79q6a2X8xYpOuzbBCjo',
};
